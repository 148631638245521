<template>
  <section id="main" class="dashboard">
    <div class="wrapper">
      <PlayerHeader :id="id" />

      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </section>
</template>

<script>
import PlayerHeader from "@/components/PlayerHeader.vue";

export default {
  components: {
    PlayerHeader
  },
  props: ["id"]
};
</script>
