<template>
  <div class="body">
    <div class="container">
      <div class="wrapper">
        <div class="fld">
          <label>Player Name</label>
          <input type="text" :value="playerName" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getPlayer"]),
    playerInfo() {
      return this.getPlayer(this.id);
    },
    playerName() {
      return this.playerInfo.title;
    }
  }
};
</script>

<style scoped>
</style>