<template>
  <div id="app">
    <header v-if="loggedIn">
      <div class="wrapper">
        <router-link tag="div" to="/">
          <a id="logo">
            <img src="./assets/images/logo.png" />
            <!-- <span :inner-html.prop="accountName | formatHeader"></span> -->
            <span>{{ accountName }}</span>
          </a>
        </router-link>
      </div>
      <div id="nav">
        <nav>
          <router-link to="/dashboard">
            <span class="icon-list"></span>
            <div class="description">Dashboard</div>
          </router-link>
          <router-link to="/players">
            <span class="icon-play"></span>
            <div class="description">Media Players</div>
          </router-link>
          <router-link to="/app">
            <span class="icon-mobile"></span>
            <div class="description">App Settings</div>
          </router-link>
          <!-- <router-link to="/stats">
            <span class="icon-pie-chart"></span>
            <div class="description">Statistics</div>
          </router-link>-->
          <router-link to="/account">
            <span class="icon-user"></span>
            <div class="description">Account Settings</div>
          </router-link>
          <a @click="logout">
            <span class="icon-door"></span>
            <div class="description">Logout</div>
          </a>
        </nav>
      </div>
    </header>
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: function() {
    return {};
  },
  computed: {
    accountName() {
      return this.$store.getters.accountInfo.name;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    }
  },
  methods: {
    ...mapActions(["setLogin", "doLogout"]),
    logout() {
      this.doLogout();
    },
    checkLogin() {
      return (
        localStorage.account &&
        JSON.parse(localStorage.account).loggedIn &&
        this.$store.getters.loggedIn
      );
    }
  },
  created() {
    if (!this.loggedIn) {
      const localAccountJSON = localStorage.account;
      if (localAccountJSON) {
        const localAccount = JSON.parse(localAccountJSON);
        if (localAccount && localAccount.loggedIn) {
          this.setLogin(localAccount);
        }
      } else {
        this.$router.replace({
          path: "login",
          query: { returnTo: this.$route.path }
        });
      }
    }
  },
  updated() {
    // if (!this.checkLogin()) {
    //   this.logout();
    // }

    if (!this.loggedIn && this.$router.currentRoute != "/login") {
      this.$router.push("/login");
    }
  }
};
</script>

<style>
@import "./assets/css/styles.css";
@import "./assets/css/modals.css";

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
<!--
<style lang="less">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
-->
