import { render, staticRenderFns } from "./AccountHeader.vue?vue&type=template&id=92520968&scoped=true&"
import script from "./AccountHeader.vue?vue&type=script&lang=js&"
export * from "./AccountHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92520968",
  null
  
)

export default component.exports