import Vue from "vue";
import Vuex from "vuex";
//import Services from "@/services/Services.js";
import AccountService from "@/services/AccountService.js";
import MediaService from "@/services/MediaService.js";
import PlayerService from "@/services/PlayerService.js";
import MobileAppService from "@/services/MobileAppService.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    account: {
      // id: "hcbc.com",
      // key: "NTJBQURDMjAzQQ",
      // id: "gowestwood.org",
      // key: "RTlDOEVDOTRERg",
      // id: "harvestamarillo.church",
      // key: "QzA0RDE0NTlFMw",
      // id: "harvestchristianfellowship.org",
      // key: "MEYxQUM1MjAyQg",
      // id: "a9.perpetuastaging.com",
      // key: "Qzc3RjlEQTU4OA",
      id: "",
      key: "",
      name: "",
      userId: 0,
      logged_in: false
    },
    media: {
      loaded: false,
      list: [],
      channels: [],
      series: []
    },
    users: {
      loaded: false,
      list: []
    },
    players: {
      loaded: false,
      list: []
    },
    mobileApp: {
      loaded: false,
      quicklinks: []
    }
  },
  getters: {
    loggedIn: state => {
      return state.account.logged_in
    },
    accountId: state => {
      return state.account.id
    },
    apiKey: state => {
      return state.account.key
    },
    accountInfo: state => {
      return state.account
    },
    mediaInfo: state => {
      return state.media
    },
    usersInfo: state => {
      return state.users
    },
    getUser: (state) => (id) => {
      return state.users.list.find(user => user.id == id)
    },
    playersInfo: state => {
      return state.players
    },
    getPlayer: (state) => (id) => {
      return state.players.list.find(player => player.unique == id)
    },
    mobileAppInfo: state => {
      return state.mobileApp
    }
  },
  mutations: {
    setLogin(state, payload) {
      state.account.logged_in = payload;
    },
    setAccount(state, payload) {
      if (!!payload.key) {
        state.account[payload.key] = payload.value;
      } else {
        state.account = payload;
      }
    },
    setMedia(state, payload) {
      if (!!payload.key) {
        state.media[payload.key] = payload.value;
      } else {
        state.media = payload;
      }
    },
    setUsers(state, payload) {
      if (!!payload.key) {
        state.users[payload.key] = payload.value;
      } else {
        state.users = payload;
      }
    },
    setPlayers(state, payload) {
      if (!!payload.key) {
        state.players[payload.key] = payload.value;
      } else {
        state.players = payload;
      }
    },
    setMobileApp(state, payload) {
      if (!!payload.key) {
        state.mobileApp[payload.key] = payload.value;
      } else {
        state.mobileApp = payload;
      }
    },
    addMediaFile(state, payload) {
      state.media.list.unshift({
        id: payload.id || Math.random() * 1000,
        title: payload.title || "",
        date: payload.date || new Date().toLocaleDateString(),
        url: payload.url || "",
        player_url: payload.player_url || "",
        channel_name: payload.channel_name || "",
        series_name: payload.series_name || "",
        speaker: payload.speaker || "",
        type: payload.type || "",
        size: payload.size || "",
        tags: payload.tags || "",
        thumbnail: payload.thumbnail || ""
      });
    }
  },
  actions: {
    async doLogin(context, creds) {
      let data = {};
      await AccountService.login(creds).then(response => {
        if (response.data.success) {
          context.dispatch("setLogin", response.data.data);
          localStorage.account = JSON.stringify(Object.assign({
            loggedIn: true
          }, response.data.data));
        } else {
          // eslint-disable-next-line
          console.log("doLogin: not successul")
        }

        data = response.data;
      });
      return data;
    },
    setLogin(context, payload) {
      context.commit("setAccount", {
        id: payload.id,
        name: payload.name,
        userId: payload.userid,
        logged_in: true
      });
      context.commit("setAccount", {
        key: "key",
        value: payload.token
      });

      AccountService.updateAuth({
        accountId: payload.id,
        apiKey: payload.token
      });
    },
    doLogout(context) {
      localStorage.clear();
      context.commit("setAccount", {
        id: "",
        key: "",
        name: "",
        userId: 0,
        logged_in: false
      });
      AccountService.updateAuth({
        accountId: "",
        apiKey: ""
      });
      context.commit("setMedia", {
        loaded: false,
        list: [],
        channels: [],
        series: []
      });
      context.commit("setUsers", {
        loaded: false,
        list: []
      });
      context.commit("setPlayers", {
        loaded: false,
        list: []
      });
      context.commit("setMobileApp", {
        loaded: false,
        quicklinks: []
      });
    },
    async getMedia(context) {
      MediaService.getDashboard({
          limit: 0
        })
        .then(response => {
          context.commit("setMedia", {
            list: response.data.media.files,
            loaded: true
          });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log("There was an error:", error.message);
        });

    },
    async getUsers(context) {
      AccountService.getUsers()
        .then(response => {
          context.commit("setUsers", {
            list: response.data.data.users,
            loaded: true
          });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log("There was an error:", error.message);
        });
    },
    async getPlayers(context) {
      PlayerService.getPlayers()
        .then(response => {
          context.commit("setPlayers", {
            list: response.data.data.players,
            loaded: true
          });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log("There was an error:", error.message);
        });

    },
    async getMobileApp(context) {
      MobileAppService.getQuicklinks()
        .then(response => {
          const quicklinks = response.data.data.quicklinks;
          context.commit("setMobileApp", {
            quicklinks: quicklinks,
            loaded: true
          });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log("There was an error:", error.message);
        });

    },
    async addMediaFile(context, payload) {
      context.commit("addMediaFile", payload)
    },
    async uploadMediaFile(context, payload) {
      await MediaService.uploadFile(payload, context)
        .then(response => {
          //context.commit("addMediaFile", response.data.data);
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log("There was an error:", error.message);
        });
    },
    async finalizeMediaFile(context, payload) {
      await MediaService.saveFile(payload).then(response => {
        context.commit("addMediaFile", response.data.data)
      })
    }
  }
});