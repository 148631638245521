<template>
  <div class="header">
    <h1 :inner-html.prop="this.$route.meta.display | formatHeader"></h1>

    <div class="secNav">
      <nav>
        <router-link to="/account/settings">
          <div class="description">Account Settings</div>
        </router-link>
        <router-link to="/account/users">
          <div class="description">Users</div>
        </router-link>
        <router-link to="/account/profile">
          <div class="description">My Profile</div>
        </router-link>
        <router-link to="/account/cancel">
          <div class="description">Cancel Account</div>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
</style>