<template>
  <a
    class="media table_row"
    :data-file="media.id"
    :data-search-tags="searchTags"
    :data-type="media.type"
    :class="media.type"
    @click="$emit('file-details', media.id)"
  >
    <div class="wrapper">
      <div class="table_col media_thumbnail">
        <div :style="{ backgroundImage: 'url(\'' + media.thumbnail + '\')' }"></div>
      </div>
      <div class="table_col media_title">
        <div>{{ media.title }}</div>
        <div class="text_small">
          <strong>{{ media.speaker }}</strong>
          {{ media.date | formatDate }}
        </div>
      </div>
      <div class="table_col media_details">
        <div class="media_type" :data-type="media.type">{{ media.type }}</div>
        {{ media.size | formatBytes }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    media: Object
  },
  computed: {
    searchTags() {
      return (
        this.media.channel_unique +
        " " +
        this.media.series_unique +
        " " +
        this.media.title +
        " " +
        this.media.type +
        " " +
        this.media.date +
        " " +
        this.media.channel_name +
        " " +
        this.media.series_name +
        " " +
        this.media.speaker +
        " " +
        this.media.tags
      );
    }
  }
};
</script>

<style scoped></style>
