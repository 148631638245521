import Api from "@/services/Api.js";

export default {
  getDashboard() {
    return Api.get(
      "/dashboard?limit=0"
    );
  },
  getStats(file_id) {
    return Api.get(
      "/stats?fileid=" + file_id
    )
  },
  uploadFile(payload) {
    return Api.post("https://media.perpetuatech.com/includes/js/lib/plupload-2.1.2/upload.php", payload.formData, {
      onUploadProgress: payload.onProgress
    })
  },
  saveFile(payload) {
    return Api.post("/upload/finalize", payload)
  }
};