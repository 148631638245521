import Api from "@/services/Api.js";

export default {
    login(options) {
        const form_data = new FormData();
        form_data.set("email", options.email);
        form_data.set("password", options.password);
        return Api.post("/login", form_data)
    },
    updateAuth(options) {
        Api.defaults.headers.common['Authentication'] = "Basic " + options.accountId + ":" + options.apiKey;
    },
    getUsers() {
        return Api.get(
            "/account/users"
        );
    }
};