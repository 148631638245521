<template>
  <div class="modal_window">
    <div class="backdrop modal_close" @click="closeModal"></div>
    <div class="modal_container" :class="{ active: activeModal }">
      <div class="square_buttons">
        <a class="button green">
          <span class="icon-check"></span>
          <span class="hover">Save</span>
        </a>
        <a class="button red" @click="closeModal">
          <span class="icon-close"></span>
          <span class="hover">Cancel</span>
        </a>
      </div>
      <div class="header">
        <div class="title">Edit Widget #{{ id }}</div>
      </div>
      <div class="body">
        <div class="container">
          <div class="fld select">
            <select name="fld_widget_type">
              <option value>Select a Widget</option>
              <option value="notes">Notes</option>
            </select>
          </div>
          <div class="fld">
            <label>Title:</label>
            <input type="text" name="title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number
    },
    modalActive: {
      type: Boolean
    }
  },
  data() {
    return {
      showModal: true
    };
  },
  computed: {
    activeModal() {
      return this.modalActive ? this.showModal : false;
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        this.showModal = true;
        this.$emit("close-modal");
      }, 300);
    }
  }
};
</script>

<style scoped>
#main .modal_window .header {
  padding: 50px;
}

#main .modal_window .header .title {
  bottom: 10px;
}
</style>