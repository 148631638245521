<template>
  <div id="file_settings" class="modal_window">
    <div class="backdrop modal_close" @click="closeModal"></div>
    <div class="modal_container tabbing" :class="{ active: activeModal }">
      <div class="square_buttons">
        <a class="button green">
          <span class="icon-check"></span>
          <span class="hover">Save</span>
        </a>
        <a class="button red" @click="closeModal">
          <span class="icon-close"></span>
          <span class="hover">Cancel</span>
        </a>
      </div>
      <div class="header" :style="{ backgroundImage: 'url(\'' + file.thumbnail + '\')' }">
        <div class="secNav tabs">
          <nav>
            <a
              class="tab"
              @click="activeTab = 'settings'"
              :class="[activeTab == 'settings' ? 'active' : '']"
            >Settings</a>
            <a
              class="tab"
              @click="activeTab = 'files'"
              :class="[activeTab == 'files' ? 'active' : '']"
            >Files</a>
            <a
              class="tab"
              @click="activeTab = 'share'"
              :class="[activeTab == 'share' ? 'active' : '']"
            >Share</a>
            <a
              class="tab"
              @click="activeTab = 'stats'"
              :class="[activeTab == 'stats' ? 'active' : '']"
            >Statistics</a>
          </nav>
        </div>
        <div class="title">{{ file.title }}</div>
      </div>
      <div class="body tab_containers" :class="activeTab">
        <div
          data-tab="settings"
          class="container tab_container"
          :class="[activeTab == 'settings' ? 'active' : '']"
        >
          <div class="fld">
            <label>Title:</label>
            <input type="text" name="date" :value="file.title" />
          </div>
          <div class="fld">
            <label>Author:</label>
            <input type="text" name="author" :value="file.speaker" />
          </div>
          <div class="fld50">
            <div class="fld">
              <label>Date:</label>
              <input type="text" name="date" :value="file.date | formatDate" />
              <div class="fld_tooltip">This is the date when the recording occurred.</div>
            </div>
            <div class="fld select right">
              <label>Channel:</label>
              <input type="text" name="channel" :value="file.channel_name" />
            </div>
          </div>
          <div class="fld50">
            <div class="fld">
              <label>Duration:</label>
              <input type="text" name="duration" :value="file.duration" />
            </div>
            <div class="fld right">
              <label>Series:</label>
              <input type="text" name="series" :value="file.series_name" />
            </div>
          </div>
          <div class="fld">
            <label>Description:</label>
            <textarea name="description" :value="file.description"></textarea>
            Look at https://github.com/davidroyer/vue2-editor or
            https://tiptap.scrumpy.io for editors.
          </div>
          <div class="fld">
            <label>Tags:</label>
            <input type="text" name="tags" :value="file.tags" />
          </div>
        </div>

        <div
          data-tab="files"
          class="container tab_container"
          :class="[activeTab == 'files' ? 'active' : '']"
        >
          <h3>
            Media
            <strong>Files</strong>
          </h3>
          <div class="table">
            <div class="table_row">
              <div class="wrapper" v-for="attachment in file.attachments" :key="attachment.id">
                <div class="table_col">{{ attachment.type }}</div>
                <div class="table_col">{{ attachment.format }}</div>
                <div class="action">
                  <span class="icon-remove"></span>
                </div>
              </div>
            </div>
            <div class="buttons">
              <div class="button default right">Upload</div>
            </div>
          </div>
          <h3>Attachments / Downloads</h3>
          <div class="table">
            <div class="table_row">
              <div class="wrapper">
                <div class="table_col">Sermon Outline</div>
                <div class="table_col">PDF</div>
              </div>
              <div class="wrapper">
                <div class="table_col">Audio Download</div>
                <div class="table_col">MP3</div>
              </div>
            </div>
            <div class="buttons">
              <div class="button default right">Upload</div>
            </div>
          </div>Look at https://github.com/rowanwins/vue-dropzone or https://www.raymondcamden.com/2019/08/08/drag-and-drop-file-upload-in-vuejs for uploads
        </div>

        <div
          data-tab="share"
          class="container tab_container"
          :class="[activeTab == 'share' ? 'active' : '']"
        >
          <h3>
            Player
            <strong>Link</strong>
          </h3>
          <pre>{{ file.player_url }}</pre>
          <br />
          <br />
          <h3>
            Embed
            <strong>Media</strong>
          </h3>
          <label>
            Width:
            <input type="text" class="file_dim" data-dim="width" value="640" />
          </label>
          <label>
            Height:
            <input type="text" class="file_dim" data-dim="height" value="360" />
          </label>
          <pre :data-url="file.id">
&lt;iframe width="640" height="360" frameborder="0" src="https://media.perpetuatech.com/embed/{{
              file.id
            }}" allowfullscreen&gt;&lt;/iframe&gt;
          </pre>
        </div>
        <div
          data-tab="stats"
          class="container tab_container"
          :class="[activeTab == 'stats' ? 'active' : '']"
        >
          <apexchart type="line" :options="stats.chartOptions" :series="stats.chartSeries"></apexchart>
          <div style="visibility: hidden;">{{stats.chartSeries}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: Object,
    stats: Object,
    modalActive: Boolean
  },
  data() {
    return {
      activeTab: "settings",
      showModal: true
    };
  },
  computed: {
    activeModal() {
      return this.modalActive ? this.showModal : false;
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        this.activeTab = "settings";
        this.showModal = true;
        this.$emit("close-modal");
      }, 300);
    }
  }
};
</script>

<style lang="less" scoped>
[data-tab="files"] .table_row .wrapper .action {
  position: absolute;
  right: -20px;
}

#main .body.stats {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
