<template>
  <div class="player_container" :class="layoutClass" :data-layout="layoutType">
    <div class="player_grid_container">
      <div class="widget_container video_container" data-widget="0">
        <div class="video_container_wrapper">
          <span class="icon-play"></span>
        </div>
      </div>

      <div class="widget_container" v-for="(i, index) in 4" :key="index" :data-widget="i">
        <div class="player_widget">
          <div class="player_widget_header">Edit Widget {{ i }}</div>
          <div class="player_widget_body">
            <div class="player_widget_body_container">
              <a class="widget_customize" @click="$emit('edit-widget', i)">Customize</a>
              <div class="player_widget_content_wrapper"></div>
            </div>
          </div>
          <div class="player_widget_footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layout: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    layoutClass() {
      const layoutPosition = this.layoutConvert(this.layout).split("_")[1];
      return `player_layout_${layoutPosition}`;
    },
    layoutType() {
      return this.layoutConvert(this.layout);
    }
  },
  methods: {
    layoutConvert(layout) {
      const layout_arr = layout.split("-");
      if (layout_arr.length <= 1) return layout;
      let [widget_count, ...layout_type] = layout_arr;

      switch (widget_count) {
        case "4":
          widget_count = "four";
          break;
        case "3":
          widget_count = "three";
          break;
        case "2":
          widget_count = "two";
          break;
        case "1":
          widget_count = "one";
          break;
        default:
          widget_count = "fullbox";
      }

      switch (layout_type.join()) {
        case "tile":
          layout_type = "wrapped";
          break;
        case "under_tile":
          layout_type = "under_wrapped";
          break;
        case "under":
        case "side":
        default:
      }

      return widget_count == "fullbox"
        ? widget_count
        : [widget_count, layout_type].join("_");
    }
  }
};
</script>

<style>
@import "../assets/css/widget_layout.css";
</style>

<style scoped>
.player_widget {
  position: relative;
  height: 100%;
}
</style>