<template>
  <div class="body">
    <div id="info" class="container">
      <div class="wrapper">
        <div class="fld">
          <label>Organization</label>
          <input type="text" :value="accountName" />
        </div>
        <div class="fld">
          <label>Domain</label>
          <input type="text" :value="accountDomain" />
        </div>
        <!--
            <div class="fld">
              <div class="fld fld50 fld_thumb">
                <label>Default Image</label>
                <img id="thumbnail" src="images/img_thumb.jpg" />
              </div>
              <div class="fld fld50 right fld_thumb">
                <label>iTunes Image</label>
                <img id="thumbnail" src="images/img_thumb.jpg" />
              </div>
            </div>
        -->
        <div class="buttons">
          <router-link to="/account/save" class="button right green">Save Changes</router-link>
          <router-link to="/dashboard" class="button right default">Cancel Changes</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    accountName() {
      return this.$store.getters.accountInfo.name;
    },
    accountDomain() {
      return this.$store.getters.accountInfo.id;
    }
  }
};
</script>

<style lang="scss" scoped></style>
