<template>
  <div class="modal_window">
    <div class="backdrop modal_close" @click="closeModal"></div>
    <div
      id="file_upload"
      class="modal_container"
      data-accept="video/*, audio/*"
      :class="[activeModal == 'file_upload' ? 'active' : '', { dragover: draggingOver }]"
      @drop.prevent="addFile"
      @dragover.prevent
      @dragenter="draggingOver = true"
      @dragleave="draggingOver = false"
    >
      <div class="wrapper">
        <h3>
          Upload
          <strong>Media</strong>
        </h3>
        <p>Some instructions on how to upload files to the Media Platform.</p>
        <div class="uploader">
          <div class="upload_list" v-if="local_files.length">
            <div
              class="uploader_file"
              v-for="file in local_files"
              :key="file.key"
              :data-status="file.status"
              :data-uploadable="file.uploadable"
            >
              <h3>
                {{ file.name }}
                <span>({{ file.size | formatBytes }})</span>
              </h3>
              <div class="uploader_cancel" @click="removeFile(file)" v-if="file.progress < 100">
                Cancel Upload
                <span class="icon-remove"></span>
              </div>
              <div class="uploader_status" v-if="uploading">
                <div class="bar" :style="{ width: file.progress + '%' }">{{ file.progress_text }}</div>
              </div>
            </div>

            <div class="buttons" v-if="!uploading">
              <input
                type="file"
                id="fileElem"
                accept="video/*, audio/*"
                multiple
                style="display:none"
                @change="addFile"
              />
              <a class="button left grey" @click.prevent="fileManual">Add More Files</a>
              <a class="button right green" @click.prevent="upload">Upload</a>
              <a class="button right red" @click.prevent="clearUploading">Clear</a>
            </div>

            <div class="buttons" v-else>
              <h3>Total</h3>
              <div class="uploader_status" v-if="uploading">
                <div class="bar" :style="{ width: totalUpload + '%' }">{{ totalUpload }}%</div>
              </div>
            </div>
          </div>
          <div class="empty_buttons" v-else>
            <input
              type="file"
              id="fileElem"
              accept="video/*, audio/*"
              multiple
              style="display:none"
              @change="addFile"
            />
            <a class="button" @click.prevent="fileManual">Choose Local Files</a>
            <strong>OR</strong>
            <a
              class="button modal_switch"
              @click="switchModal('file_external')"
            >Link to External File</a>
          </div>
        </div>
      </div>
    </div>
    <div
      id="file_external"
      class="modal_container"
      :class="[activeModal == 'file_external' ? 'active' : '']"
    >
      <div class="wrapper">
        <h3>
          Upload
          <strong>Media</strong>
        </h3>
        <p>Some instructions on how to upload files to the Media Platform.</p>
        <div class="fld">
          <label>External Link:</label>
          <input
            type="text"
            name="link"
            v-model="external_file.url"
            placeholder="www.youtube.com/my-video"
            autocomplete="off"
          />
        </div>
        <div class="fld">
          <label>Title:</label>
          <input type="text" name="title" v-model="external_file.title" autocomplete="off" />
        </div>
        <div class="fld fld50">
          <div class="fld">
            <label>Channel:</label>
            <input type="text" name="channel" autocomplete="off" />
          </div>
          <div class="fld right">
            <label>Series:</label>
            <input type="text" name="series" autocomplete="off" />
          </div>
        </div>
        <div class="buttons" v-if="!saving">
          <a class="button modal_switch" @click="switchModal('file_upload')">Back to Upload</a>
          <a class="button default modal_close" @click="closeModal">Cancel</a>
          <a class="button right green" @click="saveExternal">Save</a>
        </div>
        <div class="loading" v-if="saving">Please wait...</div>
        <div class="loading" v-if="error">{{ error }}</div>
      </div>
    </div>
    <div
      id="file_uploading"
      class="modal_container"
      :class="[activeModal == 'file_uploading' ? 'active' : '']"
    >
      <div class="wrapper">
        <h3>
          Uploading 15
          <strong>Media Files...</strong>
        </h3>
        <p>Some instructions on how to upload files to the Media Platform.</p>
        <div class="uploader active">
          <div class="uploader_file">
            <h3>media_file.mp4</h3>
            <div class="uploader_cancel">
              Cancel Upload
              <span class="icon-remove"></span>
            </div>
            <div class="uploader_status">
              <div class="bar">56%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      currentModal: "file_upload",
      saving: false,
      uploading: false,
      error: null,
      draggingOver: false,
      local_files: [],
      uploading_files: [],
      uploading_errors: [],
      external_file: {}
    };
  },
  props: {
    modalActive: Boolean
  },
  computed: {
    activeModal() {
      return this.modalActive ? this.currentModal : null;
    },
    totalUpload() {
      return Math.round(
        this.local_files.reduce((a, f) => a + f.progress, 0) /
          this.local_files.length
      );
    }
  },
  methods: {
    ...mapActions([
      "addMediaFile",
      "uploadMediaFile",
      "saveFile",
      "finalizeMediaFile"
    ]),
    switchModal(to) {
      this.currentModal = to;
    },
    closeModal() {
      this.currentModal = "";
      setTimeout(() => {
        this.external_file = {};
        this.currentModal = "file_upload";
        this.$emit("close-modal");
      }, 300);
    },

    addFile(e) {
      this.draggingOver = false;
      let droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f, i) => {
        f.uploadable = false;
        if (f.type) {
          // figure out how to make sure the file is an accepted type
          switch (f.type.split("/")[0]) {
            case "audio":
            case "video":
              f.uploadable = true;
              break;
            default:
          }
        }
        f.key = `${Date.now()}_${i}`;
        f.progress = 0;
        f.progress_text = 0;
        f.status = "";
        this.local_files.push(f);
      });
    },
    removeFile(file) {
      this.local_files = this.local_files.filter(f => {
        return f.key != file.key;
      });
    },
    clearUploading() {
      this.local_files = [];
      this.uploading_files = [];
      this.uploading_errors = [];
    },
    updateUploadProgress(key, progress) {
      this.local_files = this.local_files.filter(f => {
        if (f.key == key) {
          f.progress = progress;
          f.progress_text = `${progress}%`;
        }
        return f;
      });
    },
    clearInvalid() {
      this.local_files = this.local_files.filter(f => {
        if (f.uploadable) {
          return f;
        }
      });
    },
    upload() {
      this.clearInvalid();

      this.uploading = true;

      this.local_files.forEach(f => {
        this.uploading_files.push(f.key);

        let formData = new FormData();
        formData.append("file", f);
        formData.append("name_original", f.name);
        formData.append("size", f.size);
        formData.append("file_type", f.type);
        formData.append(
          "name",
          `p${
            Math.random()
              .toString()
              .split(".")[1]
          }.${f.name.split(".")[1]}`
        );

        this.local_files = this.local_files.filter(file => {
          if (file.key == f.key) {
            file.status = "uploading";
          }
          return file;
        });

        let _updateUploadProgress = this.updateUploadProgress;
        this.uploadMediaFile({
          formData: formData,
          onProgress(e) {
            if (e.lengthComputable) {
              const percentage = Math.round((e.loaded * 100) / e.total);
              _updateUploadProgress(f.key, percentage);
            }
          }
        })
          .then(res => {
            // eslint-disable-next-line
            console.log("done uploading");

            this.updateUploadProgress(f.key, 100);

            this.local_files = this.local_files.filter(file => {
              if (file.key == f.key) {
                file.status = "processing";
                file.progress_text = "Processing...";
              }
              return file;
            });
            // send it to the API to move the file and get the file's details back
            this.finalizeMediaFile(formData)
              .then(response => {
                this.$emit("reload-media");

                this.local_files = this.local_files.filter(file => {
                  if (file.key == f.key) {
                    file.status = "complete";
                    file.progress_text = "Complete!";
                  }
                  return file;
                });

                this.uploading_files = this.uploading_files.filter(fk => {
                  return fk != f.key;
                });

                if (
                  !this.uploading_files.length &&
                  !this.uploading_errors.length
                ) {
                  setTimeout(() => {
                    this.closeModal();
                    setTimeout(() => {
                      this.uploading = false;
                      this.saving = false;
                      this.local_files = [];
                    }, 300);
                  }, 500);
                }
              })
              .catch(e => {
                this.local_files = this.local_files.filter(file => {
                  if (file.key == f.key) {
                    file.status = "error";
                    file.progress_text = e.response.data.message;
                    this.uploading_errors.push(file);
                  }
                  return file;
                });

                this.uploading_files = this.uploading_files.filter(fk => {
                  return fk != f.key;
                });

                // eslint-disable-next-line
                console.log(JSON.stringify(e.response.data));
              });
          })
          .catch(e => {
            // eslint-disable-next-line
            console.error(JSON.stringify(e.message));
          });
      });
    },
    fileManual() {
      document.getElementById("fileElem").click();
    },

    saveExternal() {
      this.error = null;
      this.saving = true;
      this.addMediaFile({
        title: this.external_file.title,
        url: this.external_file.url,
        date: moment(String(new Date())).format("YYYY-MM-DD"),
        channel_name: "",
        series_name: "",
        speaker: "",
        type: "video",
        tags: ""
      })
        .then(response => {
          this.$emit("reload-media");
          this.closeModal();
          this.saving = false;
        })
        .catch(error => {
          this.saving = false;
          this.error = error.message;
        });
    }
  }
};
</script>

<style scoped>
.dragover .uploader {
  border: #8ab6ce dashed 4px;
}
.upload_list {
  border-top: 1px solid #ccc;
}
</style>
