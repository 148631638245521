<template>
  <div class="body">
    <div class="layout_section" v-for="section in sections" :key="section.id">
      <label>{{ section.label }}</label>
      <div class="layout_container">
        <router-link
          v-for="widgetLayout in section.layouts"
          :key="widgetLayout"
          :to="`/player/${playerId}/widgets?layout=${widgetLayout}`"
        >
          <WidgetLayout :layout="widgetLayout" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetLayout from "@/components/WidgetLayout.vue";
export default {
  components: {
    WidgetLayout
  },
  data() {
    return {
      playerId: this.$route.params.id,
      sections: [
        {
          id: "four-column",
          label: "Four Column Layout",
          layouts: ["four_under", "four_wrapped"]
        },
        {
          id: "three-column",
          label: "Three Column Layout",
          layouts: ["three_under", "three_wrapped", "three_under_wrapped"]
        },
        {
          id: "two-column",
          label: "Two Column Layout",
          layouts: ["two_under", "two_wrapped"]
        },
        {
          id: "one-column",
          label: "One Column Layout",
          layouts: ["one_under", "one_side"]
        },
        {
          id: "no-widget",
          label: "No Widget Layout",
          layouts: ["fullbox"]
        }
      ]
    };
  }
};
</script>

<style scoped>
.layout_section {
  padding: 2em;
  margin: 2em;
  background-color: #fff;
  display: flex;
}
.layout_container {
  position: relative;
  display: flex;
}
.layout_section label {
  width: 250px;
  padding: 0 5em;
  font-weight: bold;
  font-size: 1.2em;
  box-sizing: border-box;
}

.layout_section >>> div.player_container {
  min-width: auto;
  width: auto;
  margin: 0;
}
.layout_section >>> :not(:last-child) div.player_container {
  margin-right: 100px;
}
.layout_section >>> .player_grid_container {
  width: 220px;
  padding: 10px;
}
.layout_section >>> .player_grid_container:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.layout_section >>> .widget_container {
  padding: 0;
  margin: 3px;
  height: calc(100% - 6px);
  min-height: 50px;
}
.layout_section >>> .widget_container .player_widget {
  display: none;
}
</style>

<style>
.widget_container {
  background-color: #9d9d9d;
}
.video_container_wrapper {
  background-color: #424242;
}
</style>