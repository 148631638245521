<template>
  <section id="main" class="dashboard">
    <div class="wrapper">
      <AccountHeader />

      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import AccountHeader from "@/components/AccountHeader.vue";

export default {
  components: {
    AccountHeader
  }
};
</script>

<!--
<style>
@import "../assets/css/accounts.css";
</style>
-->
<style lang="less" scoped></style>
