import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;

Vue.component('apexchart', VueApexCharts)

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MMMM Do, YYYY");
  }
});

Vue.filter("sqlDate", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
});

Vue.filter("formatBytes", function (value) {
  if (!value) return "0 Bytes";
  var k = 1000;
  var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var i = Math.floor(Math.log(value) / Math.log(k));
  return (value / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
});

Vue.filter("formatHeader", function (value) {
  if (!value) return "";
  let value_array = value.trim().split(" ");
  let last_value = value_array[value_array.length - 1];
  if (value_array.length < 2) {
    return value;
  }
  value_array[value_array.length - 1] = `<strong>${last_value}</strong>`;
  return value_array.join(" ");
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");