<template>
  <div class="header">
    <h1 :inner-html.prop="this.$route.meta.display | formatHeader"></h1>
    <div class="secNav">
      <nav>
        <router-link to="settings">
          <div class="description">Player Settings</div>
        </router-link>
        <router-link to="layout">
          <div class="description">Layout</div>
        </router-link>
        <router-link to="widgets">
          <div class="description">Widgets</div>
        </router-link>
        <!-- <router-link :to="`/player/${id}/settings`">
          <div class="description">Player Settings</div>
        </router-link>
        <router-link :to="`/player/${id}/layout`">
          <div class="description">Layout</div>
        </router-link>
        <router-link :to="`/player/${id}/widgets`">
          <div class="description">Widgets</div>
        </router-link>-->
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {};
  }
};
</script>

<style scoped>
</style>