<template>
  <section id="main">
    <div class="wrapper">
      <div class="header">
        <h1>
          App
          <strong>Settings</strong>
        </h1>
      </div>
      <div class="body">
        <div class="container" v-if="isLoaded">
          <div class="wrapper">
            <section>
              <a class="button green right" @click="editQuicklink">Add Quicklink</a>
              <h3>App Quicklinks</h3>
              <div class="table">
                <Quicklink
                  v-for="quicklink in quicklinks"
                  :key="quicklink.id"
                  :quicklink="quicklink"
                ></Quicklink>
              </div>
            </section>
            <section>
              <h3>Channels</h3>
              <div class="table">
                <div v-for="channel in channels" :key="channel.id" class="table_row">
                  <label class="wrapper">
                    <div class="table_col table_checkbox">
                      <input type="checkbox" />
                    </div>
                    <div class="table_col media_title">{{ channel.name }}</div>
                    <div class="table_col"></div>
                  </label>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="loading" v-else>Loading</div>
      </div>
    </div>

    <QuicklinkEdit
      :quicklink="{id: 0, name: '', url: ''}"
      :modalActive="modalActive"
      :showModal="showModal"
      @close-modal="closeModal"
    ></QuicklinkEdit>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Quicklink from "@/components/Quicklink.vue";
import QuicklinkEdit from "@/components/QuicklinkEdit.vue";

export default {
  components: {
    Quicklink,
    QuicklinkEdit
  },
  data() {
    return {
      showModal: false,
      modalActive: false
    };
  },
  computed: {
    isLoaded() {
      return this.$store.getters.mobileAppInfo.loaded;
    },
    quicklinks() {
      return this.$store.getters.mobileAppInfo.quicklinks;
    },
    channels() {
      return this.$store.getters.mediaInfo.channels;
    }
  },
  methods: {
    ...mapActions(["getMobileApp"]),
    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        this.modalActive = false;
      }, 300);
    },
    editQuicklink() {
      this.modalActive = true;
      setTimeout(() => {
        this.showModal = true;
      }, 1);
    }
  },
  created() {
    if (!this.isLoaded) {
      this.getMobileApp();
    }
  }
};
</script>

<style scoped>
.button.right {
  float: none;
  position: absolute;
  top: -10px;
  right: 0;
}
</style>
