<template>
  <div class="body">
    <div class="container" v-if="isLoaded">
      <div class="table">
        <router-link
          v-for="user in userList"
          :key="user.id"
          :to="user.id == accountInfo.userId ? '/account/profile' : '/account/user/' + user.id"
          class="table_row"
          :class="[user.active == 1 ? '' : 'inactive']"
        >
          <div class="wrapper">
            <div class="table_col media_title" v-if="user.name.length">
              {{ user.name }}
              <span class="user_email">{{ user.email }}</span>
            </div>
            <div class="table_col media_title" v-else>{{ user.email }}</div>
            <div
              class="table_col media_details"
              v-if="user.last_login"
            >Last Login: {{ user.last_login | formatDate }}</div>
            <div class="table_col media_details" v-else>Has never logged in</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="loading" v-else>Loading</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
//import AccountService from "@/services/AccountService.js";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUser", "accountInfo"]),
    isLoaded() {
      return this.$store.getters.usersInfo.loaded;
    },
    userList() {
      return this.$store.getters.usersInfo.list;
    }
  },
  methods: {
    ...mapActions(["getUsers"])
  },
  created() {
    if (!this.isLoaded) {
      this.getUsers(); // this.$store.dispatch('getUsers')
    }
  }
};
</script>

<style scoped>
.user_email {
  font-size: 0.7em;
  display: block;
}
</style>
