<template>
  <div class="body">
    <div id="info" class="container">
      <div class="wrapper">
        <section>
          <div class="fld">
            <label>Name</label>
            <input type="text" name="name" :value="userName" />
          </div>
          <div class="fld">
            <label>Email</label>
            <input type="text" name="email" :value="userEmail" />
          </div>
          <div class="fld fld_switch" v-if="!isUser">
            <label>
              <input
                type="checkbox"
                name="active"
                value="1"
                class="ios-switch bigswitch"
                :checked="userActive"
              />
              <div>
                <div></div>
              </div>
              <span>Active</span>
            </label>
          </div>
        </section>
        <section v-if="isUser">
          <h3>Change Password</h3>
          <div class="fld">
            <label>Current Password:</label>
            <input type="text" value />
          </div>
          <div class="fld">
            <label>New Password:</label>
            <input type="text" value />
          </div>
          <div class="fld">
            <label>Confirm New Password:</label>
            <input type="text" value />
          </div>
        </section>
        <div class="buttons">
          <div class="user-actions" v-if="!isUser">
            <a class="button blue">Send Password Reset Email</a>
            <a class="button red">Deactivate User</a>
          </div>
          <router-link
            :to="'/account/user/' + this.$route.params.id + '/save'"
            class="button right green"
          >Save Changes</router-link>
          <router-link to="/account/users" class="button right default">Cancel Changes</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountService from "@/services/AccountService.js";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUser", "accountInfo"]),
    userId() {
      let user_id = this.$route.params.id;
      if (typeof user_id === "undefined") {
        user_id = this.accountInfo.userId;
      }
      return user_id;
    },
    userName() {
      return this.getUser(this.userId).name;
    },
    userEmail() {
      return this.getUser(this.userId).email;
    },
    userActive() {
      return this.getUser(this.userId).active;
    },
    isUser() {
      return this.userId == this.accountInfo.userId;
    }
  }
};
</script>

<style scoped>
section {
  margin-bottom: 5em;
}
.user-actions {
  display: inline-block;
}
</style>