import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login.vue";
import Dashboard from "./views/Dashboard.vue";
import Players from "./views/Players.vue";
import Player from "./views/Player.vue";
import PlayerSettings from "./views/PlayerSettings.vue";
import PlayerLayout from "./views/PlayerLayout.vue";
import PlayerWidgets from "./views/PlayerWidgets.vue";
import MobileApp from "./views/MobileApp.vue";
import Stats from "./views/Stats.vue";
import Settings from "./views/Settings.vue";
import AccountSettings from "./views/AccountSettings.vue";
import AccountUsers from "./views/AccountUsers.vue";
import AccountCancel from "./views/AccountCancel.vue";
import UserSettings from "./views/UserSettings.vue";
import UserSave from "./components/UserSave.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active-relative",
  linkExactActiveClass: "active",
  routes: [{
      path: "/",
      redirect: "/dashboard"
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/players",
      name: "players",
      component: Players
    },
    {
      path: "/player/:id",
      name: "player",
      redirect: "/player/:id/settings",
      component: Player,
      props: true,
      meta: {
        display: "Edit Player"
      },
      children: [{
          path: "settings",
          component: PlayerSettings,
          props: true,
          meta: {
            display: "Player Settings"
          }
        }, {
          path: "layout",
          component: PlayerLayout,
          props: true,
          meta: {
            display: "Player Layout"
          }
        },
        {
          path: "widgets",
          component: PlayerWidgets,
          props: true,
          meta: {
            display: "Player Widgets"
          }
        }

      ]
    },
    {
      path: "/app",
      name: "mobile-app",
      component: MobileApp
    },
    {
      path: "/stats",
      name: "stats",
      component: Stats
    },
    {
      path: "/account",
      name: "account",
      redirect: "/account/settings",
      component: Settings,
      children: [{
          path: "settings",
          component: AccountSettings,
          meta: {
            display: "Account Settings"
          }
        },
        {
          path: "users",
          component: AccountUsers,
          meta: {
            display: "Account Users"
          }
        },
        {
          path: "users/:id",
          component: UserSettings,
          alias: 'user/:id',
          meta: {
            display: "Edit User"
          },
          children: [{
            path: "save",
            component: UserSave
          }]
        },
        {
          path: "profile",
          component: UserSettings,
          meta: {
            display: "My Profile"
          }
        },
        {
          path: "cancel",
          component: AccountCancel,
          meta: {
            display: "Cancel Account"
          }
        }
      ]
    },
    {
      path: "*",
      redirect: "/dashboard"
    }

  ]
});