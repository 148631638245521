<template>
  <section id="main">
    <div class="header">
      <img src="../assets/images/logo_header.png" />
    </div>
    <form @submit.prevent="login">
      <div class="fld">
        <label>Email</label>
        <input type="text" v-model="email" required autofocus />
      </div>
      <div class="fld">
        <label>Password</label>
        <input type="password" v-model="password" required />
      </div>
      <div class="fld fld_submit">
        <a class="forgot-password">Forgot Password</a>
        <a class="orange button green" @click="login">Login to OneCast</a>
        <div class="loading" v-if="loading">Please wait...</div>
        <div class="loading" v-if="error">{{ error }}</div>
        <input type="submit" value="Login to OneCast" />
      </div>
    </form>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      passedPath: this.$route.query.returnTo,
      email: "",
      password: "",
      loading: false,
      error: null
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    previousPath() {
      return this.passedPath && this.passedPath != "/login"
        ? this.passedPath
        : "/dashboard";
    }
  },
  methods: {
    ...mapActions(["doLogin"]),
    login() {
      this.error = null;
      this.loading = true;
      this.doLogin({
        email: this.email,
        password: this.password,
        path: this.previousPath
      }).then(data => {
        this.loading = false;
        if (data.success) {
          this.$router.push({ path: this.previousPath });
        } else {
          this.error = data.message;
        }
      });
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ path: "/dashboard" });
    }
  },
  mounted() {
    document.body.classList.add("login");
  },
  destroyed() {
    document.body.classList.remove("login");
  }
};
</script>

<style>
@import "../assets/css/login.css";
</style>
<style scoped>
.forgot-password {
  display: inline-block;
  margin-right: 0.5em;
}
.loading {
  display: inline-block;
}
input[type="submit"] {
  position: absolute;
  left: -10000px;
}
</style>
