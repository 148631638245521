<template>
  <div>
    <div class="table_row" @click="editQuicklink">
      <div class="wrapper">
        <div class="table_col media_title">{{ quicklink.name }}</div>
        <div class="table_col media_title">{{ quicklink.url }}</div>
      </div>
    </div>
    <QuicklinkEdit
      :quicklink="quicklink"
      :modalActive="modalActive"
      :showModal="showModal"
      @close-modal="closeModal"
    ></QuicklinkEdit>
  </div>
</template>

<script>
import QuicklinkEdit from "@/components/QuicklinkEdit.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    QuicklinkEdit
  },
  props: {
    quicklink: Object
  },
  data() {
    return {
      showModal: false,
      modalActive: false
    };
  },
  computed: {},
  methods: {
    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        this.modalActive = false;
      }, 300);
    },
    editQuicklink() {
      this.modalActive = true;
      setTimeout(() => {
        this.showModal = true;
      }, 1);
    }
  }
};
</script>

<style scoped>
#main .modal_window .header {
  padding: 50px;
}

#main .modal_window .header .title {
  bottom: 10px;
}
</style>