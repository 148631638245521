<template>
  <div class="modal_window active" v-if="modalActive" v-on:keyup.esc="cancelSave">
    <div class="backdrop modal_close"></div>
    <div class="modal_container" :class="{ active: showModal }">
      <div class="header">
        <div class="title" v-if="quicklink.id > 0">Edit Quicklink</div>
        <div class="title" v-else>Add Quicklink</div>
      </div>
      <div class="body">
        <div class="container">
          <div class="fld">
            <label>Title:</label>
            <input type="text" name="title" v-model="quicklink.name" />
          </div>
          <div class="fld">
            <label>URL:</label>
            <input type="text" name="url" v-model="quicklink.url" />
          </div>
        </div>
        <div class="buttons">
          <a class="button default modal_close" @click="cancelSave">Cancel</a>
          <a class="button right green" @click="saveQuicklink">Save</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    quicklink: Object,
    modalActive: Boolean,
    showModal: Boolean
  },
  data() {
    this.cache = Object.assign({}, this.quicklink);
    return {};
  },
  computed: {
    ...mapGetters(["mobileAppInfo"])
  },
  methods: {
    ...mapMutations(["setMobileApp"]),
    saveQuicklink() {
      let newQuicklinks = this.mobileAppInfo.quicklinks;
      if (this.quicklink.id == 0) {
        newQuicklinks.push({
          id: Date.now(),
          name: this.quicklink.name,
          url: this.quicklink.url
        });
      }
      this.setMobileApp({
        key: "quicklinks",
        value: newQuicklinks
      });
      this.cache = Object.assign({}, this.quicklink);
      this.$emit("close-modal");
    },
    cancelSave() {
      if (this.quicklink.id > 0) {
        this.quicklink.name = this.cache.name;
        this.quicklink.url = this.cache.url;
      }
      this.$emit("close-modal");
    }
  }
};
</script>

<style scoped>
#main .modal_window .header {
  padding: 50px;
}

#main .modal_window .header .title {
  bottom: 10px;
}
</style>