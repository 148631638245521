<template>
  <section id="main" class="dashboard">
    <div class="wrapper">
      <div class="header">
        <a
          class="button green right modal_launcher"
          data-modal="file_upload"
          @click="modalActive = true"
        >Add Media</a>
        <h1>
          Media
          <strong>Dashboard</strong>
        </h1>
      </div>
      <div class="body">
        <div class="filter">
          <span>Search:</span>
          <div class="filter_options">
            <input
              id="searchMedia"
              class="button search"
              placeholder="Search Media"
              v-model="searchValue"
            />
          </div>
        </div>
        <div class="sort display">
          <span>Display:</span>
          <div class="filter_options">
            <span
              class="icon-menu"
              data-display="list"
              :class="{ active: viewList }"
              @click="viewDisplay = 'list'"
            ></span>
            <span
              class="icon-grid"
              data-display="tiled"
              :class="{ active: viewTiled }"
              @click="viewDisplay = 'tiled'"
            ></span>
          </div>
        </div>
        <div
          id="media_list"
          class="table container"
          v-if="mediaListAll.length"
          :class="{ tiled: viewTiled }"
        >
          <p class="mediaCount">
            <span>{{ filtered ? filteredFiles.length : mediaListAll.length }}</span>
            media files found
          </p>
          <div id="media_list_container" class="table container">
            <MediaCard
              v-for="media in mediaList"
              :key="media.id"
              :media="media"
              @file-details="showFileDetails"
            />
          </div>
          <div id="more"></div>
        </div>
        <div class="no_media container" v-else-if="isLoaded">
          <span class="icon icon-cloud-upload"></span>
          <h3>
            Your
            <strong>Dashboard</strong> is Empty
          </h3>
          <a class="button green upload">Add Media to Dashboard</a>
        </div>
        <div class="loading" v-else>Loading</div>
      </div>
    </div>
    <MediaUpload
      :class="{ active: modalActive }"
      @reload-media="reloadMedia()"
      @close-modal="modalActive = false"
      :modalActive="modalActive"
    ></MediaUpload>
    <MediaFile
      :file="fileDetails"
      :stats="fileStats"
      :modalActive="!!fileDetails.id"
      :class="{ active: fileDetails.id }"
      @close-modal="fileDetails = {}; resetStats()"
      @get-stats="getStats"
    ></MediaFile>
  </section>
</template>

<script>
import MediaCard from "@/components/MediaCard.vue";
import MediaFile from "@/components/MediaFile.vue";
import MediaUpload from "@/components/MediaUpload.vue";
import MediaService from "@/services/MediaService.js";
//import { constants } from "crypto";

export default {
  components: {
    MediaCard,
    MediaFile,
    MediaUpload
  },
  data() {
    return {
      isLoaded: this.$store.getters.mediaInfo.loaded,
      mediaList: [],
      filtered: false,
      filteredFiles: [],
      searchValue: "",
      viewDisplay: "list",
      limit: 10,
      modalActive: false,
      fileDetails: {},
      fileStats: {
        chartOptions: {
          chart: {
            id: "file-stats",
            width: "100%",
            zoom: {
              enabled: false
            }
          },
          xaxis: {
            type: "datetime",
            hideOverlappingLabels: true
          },
          yaxis: {
            min: 0
          }
        },
        chartSeries: [
          {
            name: "Views",
            data: []
          }
        ]
      }
    };
  },
  computed: {
    mediaListAll() {
      return this.$store.getters.mediaInfo.list;
    },
    viewList() {
      return this.viewDisplay == "list";
    },
    viewTiled() {
      return this.viewDisplay == "tiled";
    }
  },
  methods: {
    getFiles(obj) {
      const files = obj || this.mediaListAll;
      return this.mediaList.concat(
        files.slice(this.mediaList.length, this.mediaList.length + this.limit)
      );
    },
    getFile(id) {
      const files = this.mediaListAll;
      return files.filter(function(file) {
        return file.id == id;
      })[0];
    },
    getStats(id) {
      MediaService.getStats(id)
        .then(response => {
          const graph_data = response.data.stats.graph_data_v2;
          this.fileStats.chartSeries[0].data = graph_data.map(function(value) {
            return {
              x: value.label,
              y: value.value
            };
          });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log("There was an error:", error.message);
        });
    },
    reloadMedia() {
      this.mediaList = [];
      if (this.searchValue.length > 0) {
        this.filtered = true;
        this.mediaList = this.getFiles(this.searchTerm(this.searchValue));
      } else {
        this.filtered = false;
        this.mediaList = this.getFiles();
      }
    },
    resetStats() {
      this.fileStats.chartSeries[0].data = [];
    },
    handleScroll() {
      if (
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.scrollHeight
      ) {
        if (this.filtered) {
          this.mediaList = this.getFiles(this.filteredFiles);
        } else {
          this.mediaList = this.getFiles();
        }
      }
    },
    searchTerm(term) {
      let files = this.mediaListAll;
      let results = files.filter(function(file) {
        if (
          file.title.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
          file.channel_name.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
          file.series_name.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
          file.speaker.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
          file.type.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
          file.tags.toLowerCase().indexOf(term.toLowerCase()) !== -1
        ) {
          return file;
        }
      });
      this.filteredFiles = results;
      return results;
    },
    showFileDetails(id) {
      const file = this.getFile(id);
      this.fileDetails = file;
      this.getStats(file.id);
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);

    if (!this.isLoaded) {
      MediaService.getDashboard({
        limit: 0
      })
        .then(response => {
          this.$store.commit("setMedia", {
            list: response.data.media.files,
            channels: response.data.media.channels,
            series: response.data.media.series,
            loaded: true
          });
          this.mediaList = this.getFiles();
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log("There was an error:", error.message);
        });
    } else {
      this.mediaList = this.getFiles();
    }
  },
  mounted() {
    document
      .querySelector("body #searchMedia")
      .addEventListener("keyup", this.reloadMedia);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style>
@import "../assets/css/dashboard.css";
</style>

<style>
#main .tiled .table.container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  background: #fff;
}

.tiled .table_row .wrapper {
  display: block;
  border-bottom-width: 0;
  padding: 2em 6em;
}

#main .tiled .table.container .table_row {
  flex: auto;
  width: 280px;
}

.tiled .table_col.media_thumbnail {
  margin: 0;
}

.tiled .table_col:last-of-type {
  text-align: center;
}
</style>

<style lang="less" scoped></style>
