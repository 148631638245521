<template>
  <section id="main" class="players">
    <div class="wrapper">
      <div class="header">
        <h1>
          Media
          <strong>Players</strong>
        </h1>
      </div>
      <div class="body">
        <div class="container" v-if="isLoaded">
          <router-link
            v-for="player in playerList"
            :key="player.id"
            :to="'/player/' + player.unique"
            class="player_link"
          >
            <div class="wrapper">
              <h3>{{ player.title }}</h3>
              <p>{{ player.layout | layoutDisplay }}</p>
            </div>
          </router-link>
        </div>
        <div class="loading" v-else>Loading</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getPlayer"]),
    isLoaded() {
      return this.$store.getters.playersInfo.loaded;
    },
    playerList() {
      return this.$store.getters.playersInfo.list;
    }
  },
  methods: {
    ...mapActions(["getPlayers"])
  },
  filters: {
    layoutDisplay: function(layout) {
      const widget_number = layout.split("-")[0];
      let layout_display = "Widget Layout";
      if (widget_number == 0) {
        layout_display = "No " + layout_display;
      } else {
        layout_display = `${widget_number} ${layout_display}`;
      }

      return layout_display;
    }
  },
  created() {
    if (!this.isLoaded) {
      this.getPlayers();
    }
  }
};
</script>

<style>
@import "../assets/css/players.css";
</style>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
}
.player_link {
  float: none;
}
</style>
