<template>
  <section id="main" class="dashboard">
    <div class="wrapper">
      <div class="header">
        <h1>
          Media
          <strong>Statistics</strong>
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
@import "../assets/css/stats.css";
</style>

<style lang="less" scoped></style>
