<template>
  <div class="body">
    <div class="wrapper">
      <WidgetLayout :layout="playerLayout" @edit-widget="editWidget" />
    </div>
    <WidgetSettings
      :class="{ active: settingsModalActive }"
      :id="widgetId"
      :modalActive="settingsModalActive"
      @close-modal="settingsModalActive = false"
    ></WidgetSettings>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import WidgetLayout from "@/components/WidgetLayout.vue";
import WidgetSettings from "@/components/WidgetSettings.vue";
export default {
  components: {
    WidgetLayout,
    WidgetSettings
  },
  props: ["id"],
  data() {
    return {
      layout: this.$route.query.layout,
      settingsModalActive: false,
      widgetId: 0
    };
  },
  computed: {
    ...mapGetters(["getPlayer"]),
    playerInfo() {
      return this.getPlayer(this.id);
    },
    playerLayout() {
      return this.layout || this.playerInfo.layout;
    }
  },
  methods: {
    editWidget(id) {
      this.widgetId = id;
      this.settingsModalActive = true;
    }
  }
};
</script>

<style scoped>
.wrapper {
  padding: 80px;
  background-color: #fff;
}

>>> .player_container .icon-play {
  background-color: #272727;
  padding: 15px;
  border-radius: 50%;
}
>>> .widget_container {
  background-color: transparent;
}

>>> .widget_container .widget_customize {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  background-color: #39ce6a;
  box-sizing: border-box;
  color: #fff;
  font-size: 10pt;
}
</style>